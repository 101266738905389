@import "../variables";
@import "../variables-dark";


.auth-logo {
  .auth-logo-dark {
    display: $display-block;
    margin: 0 auto;
  }

  .auth-logo-light {
    display: $display-none;
    margin: 0 auto;
  }
}

.authentication-bg {
  background-size: cover;
  background: url('../../images/jpgs/bg_img.png') no-repeat center;
  display: flex;
  align-items: center;
  justify-content: center;

  .auth-logo {
    .logo {
      margin: 0 auto;
    }

    .auth-logo-light {
      display: none;
      margin: 0 auto;
    }

    .auth-logo-dark {
      margin: 0 auto;
    }
  }

  .bg-overlay {
    opacity: 0.85;
  }

  .auth-cover-card {
    .authentication-bg {
      align-items: flex-start;
      justify-content: flex-start;
    }

    .bg-overlay {
      opacity: 0.6;
    }

    .form-floating {
      label {
        color: rgba($white, 0.5);
      }

      .form-control {
        background-color: rgba($white, 0.1);
        border-color: rgba($white, 0.1);
        color: $white;
      }

      .form-floating-icon {
        color: rgba($white, 0.5);
      }
    }
  }
}

.error-font {
  font-size: 9rem;
  text-shadow: 4px 4px 6px rgba($gray-900, 0.26);
}

body[data-bs-theme="dark"] {
  .authentication-bg {
    .auth-logo-light {
      display: block;
    }

    .auth-logo-dark {
      display: none;
    }
  }
}