@import "../variables";
@import "../variables-dark";

.card {
    margin-bottom: $grid-gutter-width;
    box-shadow: var(--#{$prefix}box-shadow);
}

.card-drop {
    color: var(--#{$prefix}body-color);
}

.card-title {
    font-size: 16px;
    margin-bottom: $card-title-spacer-y;
}

.card-title-desc {
    margin-bottom: 0;
}

.card-h-100 {
    height: calc(100% - #{$grid-gutter-width});
}

@mixin card-header-variant($bg) {
    background-color: lighten($bg, 5%) !important;
    border-bottom: none
}

@each $color,
$value in $theme-colors {
    .card-header.bg-#{$color} {
        @include card-header-variant($value);
    }
}

body[data-bs-theme="bordered"] {
    .card{
        box-shadow: none;
    }
}