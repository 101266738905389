// 
// _demos.scss
// 

// Grid 
.grid-example {
  background-color: var(--#{$prefix}tertiary-bg);
  border-radius: 5px;
  font-weight: $font-weight-medium;
  padding: 10px 20px;
  font-size: .8rem;
}


// Demo Only
.button-items {
  margin-left: -8px;
  margin-bottom: -12px;

  .btn {
    margin-bottom: 12px;
    margin-left: 8px;
  }
}

// Modals

.bs-example-modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}


// Grid

.grid-structure {
  .grid-container {
    background-color: var(--#{$prefix}tertiary-bg);
    margin-top: 10px;
    font-size: .8rem;
    font-weight: $font-weight-medium;
    padding: 10px 20px;
  }
}


.ribbon {
  position: absolute;
  right: -26px;
  top: 20px;
  transform: rotate(45deg);
  color: $white;
  font-size: 13px;
  font-weight: $font-weight-medium;
  padding: 1px 22px;
  font-size: 13px;
  font-weight: 500;
}

// card radio
.card-radio {
  background-color: var(--#{$prefix}secondary-bg);
  border: 2px solid $card-border-color;
  border-radius: $border-radius;
  padding: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;

  &:hover {
    cursor: pointer;
  }
}

.card-radio-label {
  display: block;
}

.edit-btn {
  width: 35px;
  height: 35px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  right: 25px;
  margin-top: -50px;
}

.card-radio-input {
  display: none;

  &:checked + .card-radio {
    border-color: $primary !important;

    &:before {
      content: '\e9da';
      font-family: "boxicons";
      position: absolute;
      top: 10px;
      right: 25px;
      font-size: 22px;
      color: $primary;
    }
  }
}

