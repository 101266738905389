@import "../variables";
@import "../variables-dark";

.page-title-box {
  padding: 0 0.75rem;

  .breadcrumb {
    background-color: transparent;
    padding: 0;
  }

  h4 {
    font-size: 21px !important;
  }

  .page-title-right {
    @media (max-width: 575.98px) {
      display: none;
    }
  }
}

.page-title {
  font-size: 20px;
  margin: 0;
}
