@import "../variables";
@import "../variables-dark";

button,
a {
  outline: none !important;
}

.btn-warning {
  color: $white !important;
}

.btn.bg-gradient {
  border: none;
}

@mixin button-variant($bg) {
  color: $white;
  background-color: $bg;
  border-color: transparent;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: shade-color($bg, 10%) !important;
    border-color: transparent !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.15rem rgba($bg, 0.5) !important;
  }
}

.btn-light {
  color: $dark !important;

  &:hover,
  &:active,
  &:focus {
    color: $dark !important;
  }

}

@each $color,
$value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value);
  }
}

//
// outline Buttons
//

@mixin button-outline-variant($bg) {
  color: $white;
  border-color: $bg;

  &:hover,
  &:focus,
  &:active {
    color: $white !important;
    background-color: $bg !important;
    border-color: transparent !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.15rem rgba($bg, 0.5) !important;
  }
}

@each $color,
$value in $theme-colors {
  .btn-outline#{$color} {
    @include button-outline-variant($value);
  }
}

//
// Soft Buttons
// 

@mixin button-variant-subtle($bg) {
  color: $bg;
  background-color: rgba($bg, 0.1);
  border-color: transparent;

  &:hover,
  &:focus,
  &:active {
    color: $white !important;
    background-color: $bg !important;
    border-color: transparent !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.15rem rgba($bg, 0.5) !important;
  }
}

@each $color,
$value in $theme-colors {
  .btn-subtle-#{$color} {
    @include button-variant-subtle($value);
  }
}

// Soft Light button
.btn-subtle-light {
  color: $gray-600;

  &:hover,
  &:focus,
  &:active {
    color: $gray-800 !important;
  }
}

// Soft Light button
.btn-outline-light {
  color: $dark;

  &:hover,
  &:focus,
  &:active {
    color: $dark;
  }
}

//
// Split Dropdown buttons
//

.btn-light.dropdown-toggle-split {
  background-color: darken($light, 3%);;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}


// theme dark
[data-bs-theme="dark"] {
  .btn-light {
    color: $white !important;
    background-color: var(--#{$prefix}light) !important;
    border-color: var(--#{$prefix}light);

    &:hover {
      color: $white !important;
      background-color: rgba(var(--#{$prefix}light-rgb), 0.75) !important;
      border-color: rgba(var(--#{$prefix}light-rgb), 0.75);
    }
  }

  .btn-check:focus + .btn-light,
  .btn-check:focus + .btn-outline-light,
  .btn-check:focus + .btn-soft-light,
  .btn-light:focus,
  .btn-outline-light:focus,
  .btn-soft-light:focus {
    color: $white !important;
    background-color: rgba(var(--#{$prefix}light-rgb), 0.75);
    box-shadow: 0 0 0 0.15rem rgba(var(--#{$prefix}light-rgb), 0.5) !important;
  }

  .btn-check:active + .btn-light,
  .btn-check:active + .btn-outline-light,
  .btn-check:active + .btn-soft-light,
  .btn-check:checked + .btn-light,
  .btn-check:checked + .btn-outline-light,
  .btn-check:checked + .btn-soft-light,
  .btn-light.active,
  .btn-light:active,
  .btn-outline-light.active,
  .btn-outline-light:active,
  .btn-soft-light.active,
  .btn-soft-light:active,
  .show > .btn-light.dropdown-toggle,
  .show > .btn-outline-light.dropdown-toggle,
  .show > .btn-soft-light.dropdown-toggle {
    color: $white !important;
    background-color: rgba(var(--#{$prefix}light-rgb), 0.75);
  }

  // dark

  .btn-dark {
    color: $white !important;
    background-color: var(--#{$prefix}dark);
    border-color: var(--#{$prefix}dark);

    &:hover {
      color: var(--#{$prefix}light);
      background-color: rgba(var(--#{$prefix}dark-rgb), 0.75);
      border-color: rgba(var(--#{$prefix}dark-rgb), 0.75);
    }
  }

  .btn-check:focus + .btn-dark,
  .btn-check:focus + .btn-outline-dark,
  .btn-check:focus + .btn-soft-dark,
  .btn-dark:focus,
  .btn-outline-dark:focus,
  .btn-soft-dark:focus {
    color: $white;
    background-color: rgba(var(--#{$prefix}dark-rgb), 0.75) !important;
    border-color: rgba(var(--#{$prefix}dark-rgb), 0.75) !important;
  }

  .btn-check:active + .btn-dark,
  .btn-check:active + .btn-outline-dark,
  .btn-check:active + .btn-soft-dark,
  .btn-check:checked + .btn-dark,
  .btn-check:checked + .btn-outline-dark,
  .btn-check:checked + .btn-soft-dark,
  .btn-dark.active,
  .btn-dark:active,
  .btn-outline-dark.active,
  .btn-outline-dark:active,
  .btn-subtle-dark.active,
  .btn-subtle-dark:active,
  .show > .btn-dark.dropdown-toggle,
  .show > .btn-outline-dark.dropdown-toggle,
  .show > .btn-subtle-dark.dropdown-toggle {
    color: var(--#{$prefix}light);
    background-color: rgba(var(--#{$prefix}dark-rgb), 0.75) !important;
    border-color: rgba(var(--#{$prefix}dark-rgb), 0.75) !important;
  }

  // outline

  .btn-outline-light {
    color: $white !important;
    border-color: var(--#{$prefix}light);

    &:hover {
      color: $white;
      background-color: rgba(var(--#{$prefix}light-rgb), 0.75) !important;
      border-color: rgba(var(--#{$prefix}light-rgb), 0.75);
    }
  }

  .btn-outline-dark {
    color: $white !important;
    border-color: var(--#{$prefix}dark);

    &:hover {
      color: var(--#{$prefix}light);
      background-color: rgba(var(--#{$prefix}dark-rgb), 0.75) !important;
      border-color: rgba(var(--#{$prefix}dark-rgb), 0.75);
    }
  }

  // soft

  .btn-subtle-light {
    color: $white !important;
    background-color: rgba(var(--#{$prefix}light-rgb), 0.1) !important;

    &:hover {
      color: $white !important;
      background-color: var(--#{$prefix}light) !important;
    }
  }

  .btn-subtle-dark {
    color: $white !important;
    background-color: rgba(var(--#{$prefix}dark-rgb), 0.1);

    &:hover {
      color: var(--#{$prefix}light);
      background-color: var(--#{$prefix}dark) !important;
    }
  }
}