@import "../variables";
@import "../variables-dark";

.email-leftbar {
  width: 306px;
  float: left;
}

.email-rightbar {
  margin-left: 324px;
}

@media (max-width: 767px) {
  .email-leftbar {
    float: none;
    width: 100%;
  }
  .email-rightbar {
    margin: 0;
  }
}

.mail-list {
  a {
    display: block;
    color: var(--#{$prefix}body-color);
    line-height: 24px;
    padding: 10px 18px;

    &.active {
      color: $primary;
      font-weight: 500;

      h5 {
        color: $primary;
      }
    }
  }
}

.message-list {
  display: block;
  padding-left: 0;

  .list {
    position: relative;
    display: block;
    height: 50px;
    cursor: default;
    transition-duration: 0.3s;

    a {
      color: $gray-600;
    }

    &.selected {
      background-color: rgba($gray-300, 0.6);
    }

    .col-mail {
      float: left;
      position: relative;
    }

    .col-mail-1 {
      width: 320px;

      .title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-bottom: 0;
        margin-top: 6px;
      }
    }

    .col-mail-2 {
      position: absolute;
      top: 14px;
      left: 220px;
      right: 0;
      bottom: 0;

      .subject,
      .date {
        position: absolute;
        top: 0;
      }

      .subject {
        left: 0;
        right: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 500;
      }

      .date {
        right: 5px;
        width: 200px;
        padding-left: 40px;
      }
    }

    &.active,
    &.active:hover {
      box-shadow: inset 3px 0 0 $primary;
    }
  }

  &:hover {
    background-color: var(--#{$prefix}tertiary-bg);
  }
}

.email-search {
  .form-control {
    border: 0;
    padding: 0.6rem 0.75rem;
  }

  span {
    position: absolute;
    z-index: 10;
    line-height: 38px;
    right: 13px;
    top: 2px;
    color: $gray-600;
  }
}

@media (max-width: 575.98px) {
  .message-list .list .col-mail-1 {
    width: 200px;
  }
}
