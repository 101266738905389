// Vertical Sidebar - Default Light
$sidebar-bg: var(--#{$prefix}sidebar-bg);
$sidebar-border-color: var(--#{$prefix}sidebar-border-color);
$sidebar-menu-item-color: var(--#{$prefix}sidebar-menu-item-color);
$sidebar-menu-sub-item-color: var(--#{$prefix}sidebar-menu-sub-item-color);
$sidebar-menu-item-icon-color: var(--#{$prefix}sidebar-menu-item-icon-color);
$sidebar-menu-item-hover-color: var(--#{$prefix}sidebar-menu-item-hover-color);
$sidebar-menu-item-active-color: var(--#{$prefix}sidebar-menu-item-active-color);
$sidebar-menu-sub-item-active-color: var(--#{$prefix}sidebar-menu-sub-item-active-color);
$sidebar-menu-item-active-bg-color: var(--#{$prefix}sidebar-menu-item-active-bg-color);
$sidebar-menu-sub-item-line-color: var(--#{$prefix}sidebar-menu-sub-item-line-color);
$sidebar-width: 270px;
$sidebar-collapsed-width: 70px;
$sidebar-width-sm: 160px;

// Vertical Sidebar - Dark
$sidebar-dark-bg: var(--#{$prefix}sidebar-dark-bg); //2c313a
$sidebar-dark-menu-item-color: var(--#{$prefix}sidebar-dark-menu-item-color);
$sidebar-dark-menu-sub-item-color: var(--#{$prefix}sidebar-dark-menu-sub-item-color);
$sidebar-dark-menu-item-icon-color: var(--#{$prefix}sidebar-dark-menu-item-icon-color);
$sidebar-dark-menu-item-hover-color: var(--#{$prefix}sidebar-dark-menu-item-hover-color);
$sidebar-dark-menu-item-active-color: var(--#{$prefix}sidebar-dark-menu-item-active-color);
$sidebar-dark-menu-item-active-bg-color: var(--#{$prefix}sidebar-dark-menu-item-active-bg-color);
$sidebar-dark-menu-sub-item-line-color: var(--#{$prefix}sidebar-dark-menu-sub-item-line-color);

// TopBar
$header-height: 75px;
$header-bg: var(--#{$prefix}header-bg);
$header-item-color: #555b6d;
$header-dark-bg: #131516;
$header-dark-item-color: #c0c5cb;
$header-dark-mode-item-color: #c0c5cb;
$topbar-search-border: #eff0f2;
$topbar-search-bg: #fff;

// Footer
$footer-height: 60px;
$footer-bg: #f2f2f5;

// Horizontal nav
$topnav-bg: #fff;
$menu-item-color: #7b8190;
$menu-item-active-color: #1f58c7;
$topnav-dark-bg: #131516;
$menu-dark-item-color: #7e8388;
$menu-dark-item-active-color: #eeeff1;

// Utils
$rightbar-width: 320px;
$display-none: none;
$display-block: block;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-family-secondary: 'Montserrat', sans-serif;
$spacer: 1rem;
$apex-grid-color: #f8f9fa;

// Brand
$navbar-brand-box-width: 250px;
$navbar-brand-box-bg: var(--#{$prefix}navbar-brand-box-bg);
$boxed-layout-width: 1500px;
$boxed-body-bg: var(--#{$prefix}boxed-body-bg);

// table
$table-head-bg: var(--#{$prefix}table-bg);
$table-dark-border-color: tint-color($gray-800, 7.5%);

$btn-close-bg-dark: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$white}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");

// Color system
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #f5f6f8;
$gray-300: #eff0f2;
$gray-400: #e2e5e8;
$gray-500: #7d8287;
$gray-600: #a4a9b4;
$gray-700: #282c2f;
$gray-800: #1f2224;
$gray-900: #131516;
$black: #000;
$grays: (
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900
);


$blue: #038edc;
$indigo: #564ab1;
$purple: #1f58c7;
$pink: #e83e8c;
$red: #ed5555;
$orange: #f1734f;
$yellow: #f4c238;
$green: #28b765;
$teal: #050505;
$cyan: #52c6ea;
$colors: (
        "blue": $blue,
        "indigo": $indigo,
        "purple": $purple,
        "pink": $pink,
        "red": $red,
        "orange": $orange,
        "yellow": $yellow,
        "green": $green,
        "teal": $teal,
        "cyan": $cyan,
        "black": $black,
        "white": $white,
        "gray": $gray-600,
        "gray-dark": $gray-800
);

$min-contrast-ratio: 1.7;
$color-contrast-dark: $black;
$color-contrast-light: $white;

$primary: $purple;
$secondary: $gray-600;
$success: $green;
$info: $blue;
$warning: $yellow;
$orange: $orange;
$danger: $red;
$light: $gray-200;
$dark: $gray-900;

$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "pink": $pink,
        "light": $light,
        "dark": $dark,
        "white": $white
);

$enable-dark-mode: true;
$color-mode-type: data; // `data` or `media-query`
$variable-prefix: bs-; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix: $variable-prefix;
$gradient: linear-gradient(180deg, rgba($white, .15), rgba($white, 0));

$body-text-align: null;
$body-color: $gray-700;
$body-bg: #f8f9fb;
$body-secondary-color: $gray-600;
$body-secondary-bg: $white;
$body-tertiary-color: rgba($body-color, .5);
$body-tertiary-bg: $gray-100;
$body-emphasis-color: $black;

// Links
$link-color: $primary;
$link-decoration: none;
$link-shade-percentage: 20%;
$link-hover-color: shift-color($link-color, $link-shade-percentage);
$link-hover-decoration: null;

$stretched-link-pseudo-element: after;
$stretched-link-z-index: 1;

$icon-link-gap: .375rem;
$icon-link-underline-offset: .25em;
$icon-link-icon-size: 1em;
$icon-link-icon-transition: .2s ease-in-out transform;
$icon-link-icon-transform: translate3d(.25em, 0, 0);

$paragraph-margin-bottom: 1rem;


// Grid breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1320px
);

@include _assert-ascending($container-max-widths, "$container-max-widths");

$grid-columns: 12;
$grid-gutter-width: 24px;
$grid-row-columns: 6;
$container-padding-x: $grid-gutter-width * 0.5;

// Components
$border-width: 1px;
$border-widths: (
        1: 1px,
        2: 2px,
        3: 3px,
        4: 4px,
        5: 5px
);
$border-style: solid;
$border-color: $gray-300;
$border-color-translucent: $gray-300;
$border-radius: .75rem;
$border-radius-sm: .5rem;
$border-radius-lg: 0.6rem;
$border-radius-xl: 1rem;
$border-radius-xxl: 2rem;
$border-radius-pill: 50rem;
$border-radius-2xl: $border-radius-xxl;

$box-shadow: 0 0px 6px darken($body-bg, 4%);
$box-shadow-sm: 0 .125rem .25rem rgba($black, .075);
$box-shadow-lg: 0 5px 6px rgba($dark, 0.1);
$box-shadow-inset: inset 0 1px 2px rgba($black, .075);

$component-active-color: $white;
$component-active-bg: $primary;

$focus-ring-width: 0;
$focus-ring-opacity: .25;
$focus-ring-color: rgba($primary, $focus-ring-opacity);
$focus-ring-blur: 0;
$focus-ring-box-shadow: 0 0 $focus-ring-blur $focus-ring-width $focus-ring-color;

$caret-width: .3em;
$caret-vertical-align: $caret-width * .85;
$caret-spacing: $caret-width * .85;

$transition-base: all .2s ease-in-out;
$transition-fade: opacity .15s linear;
$transition-collapse: height .35s ease;
$transition-collapse-width: width .35s ease;

$aspect-ratios: (
        "1x1": 100%,
        "4x3": calc(3 / 4 * 100%),
        "16x9": calc(9 / 16 * 100%),
        "21x9": calc(9 / 21 * 100%)
);

// Typography
$font-family-sans-serif: 'IBM Plex Sans', sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base: var(--#{$prefix}font-sans-serif);
$font-family-code: var(--#{$prefix}font-monospace);
$font-size-root: null;
$font-size-base: 0.925rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * .875;
$font-size-lg: $font-size-base * 1.25;
$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;
$font-sizes: (
        1: $h1-font-size,
        2: $h2-font-size,
        3: $h3-font-size,
        4: $h4-font-size,
        5: $h5-font-size,
        6: $h6-font-size
);

$headings-margin-bottom: $spacer * .5;
$headings-font-family: null;
$headings-font-style: null;
$headings-font-weight: 500;
$headings-line-height: 1.2;
$headings-color: #35415a;

$display-font-sizes: (
        1: 5rem,
        2: 4.5rem,
        3: 4rem,
        4: 3.5rem,
        5: 3rem,
        6: 2.5rem
);

$display-font-family: null;
$display-font-style: null;
$display-font-weight: 300;
$display-line-height: $headings-line-height;
$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;
$small-font-size: 87.5%;
$sub-sup-font-size: .75em;

$text-muted: var(--#{$prefix}secondary-color); // Deprecated in 5.3.0
$initialism-font-size: $small-font-size;
$blockquote-margin-y: $spacer;
$blockquote-font-size: $font-size-base * 1.25;
$blockquote-footer-color: $gray-600;
$blockquote-footer-font-size: $small-font-size;

$hr-margin-y: $spacer;
$hr-color: inherit;

// fusv-disable
$hr-bg-color: null; // Deprecated in v5.2.0
$hr-height: null; // Deprecated in v5.2.0
// fusv-enable

$hr-border-color: null; // Allows for inherited colors
$hr-border-width: var(--#{$prefix}border-width);
$hr-opacity: .25;

// scss-docs-start vr-variables
$vr-border-width: var(--#{$prefix}border-width);
// scss-docs-end vr-variables

$legend-margin-bottom: .5rem;
$legend-font-size: 1.5rem;
$legend-font-weight: null;

$dt-font-weight: $font-weight-bold;

$list-inline-padding: .5rem;

$mark-padding: .2em;
$mark-bg: #fcf8e3;
$mark-color: $body-color;

// Tables
$table-cell-padding-y: .75rem;
$table-cell-padding-x: .75rem;
$table-cell-padding-y-sm: .40rem;
$table-cell-padding-x-sm: .40rem;
$table-cell-vertical-align: top;
$table-color: var(--#{$prefix}body-color);
$table-bg: var(--#{$prefix}secondary-bg);
$table-accent-bg: transparent;

$table-striped-color: $table-color;
$table-striped-bg-factor: .05;
$table-striped-bg: var(--#{$prefix}tertiary-bg);
$table-active-color: $table-color;
$table-active-bg-factor: .1;
$table-active-bg: var(--#{$prefix}tertiary-bg);
$table-hover-color: $table-color;
$table-hover-bg-factor: .075;
$table-hover-bg: var(--#{$prefix}tertiary-bg);
$table-border-factor: .1;
$table-border-width: var(--#{$prefix}border-width);
$table-border-color: var(--#{$prefix}border-color);


// Buttons + Forms
$input-btn-padding-y: .47rem;
$input-btn-padding-x: .75rem;
$input-btn-font-family: null;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;
$input-btn-focus-width: $focus-ring-width;
$input-btn-focus-color-opacity: $focus-ring-opacity;
$input-btn-focus-color: $focus-ring-color;
$input-btn-focus-blur: $focus-ring-blur;
$input-btn-focus-box-shadow: $focus-ring-box-shadow;
$input-btn-padding-y-sm: .25rem;
$input-btn-padding-x-sm: .5rem;
$input-btn-font-size-sm: $font-size-sm;
$input-btn-padding-y-lg: .5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-font-size-lg: $font-size-lg;
$input-btn-border-width: var(--#{$prefix}border-width);

// Buttons
$btn-color: var(--#{$prefix}body-color);
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-normal;
$btn-box-shadow: inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075);
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: .65;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, .125);

$btn-link-color: var(--#{$prefix}link-color);
$btn-link-hover-color: var(--#{$prefix}link-hover-color);
$btn-link-disabled-color: $gray-600;
$btn-link-focus-shadow-rgb: to-rgb(mix(color-contrast($link-color), $link-color, 15%));

$btn-border-radius: var(--#{$prefix}border-radius);
$btn-border-radius-sm: var(--#{$prefix}border-radius-sm);
$btn-border-radius-lg: var(--#{$prefix}border-radius-lg);

$btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$btn-hover-bg-shade-amount: 15%;
$btn-hover-bg-tint-amount: 15%;
$btn-hover-border-shade-amount: 20%;
$btn-hover-border-tint-amount: 10%;
$btn-active-bg-shade-amount: 20%;
$btn-active-bg-tint-amount: 20%;
$btn-active-border-shade-amount: 25%;
$btn-active-border-tint-amount: 10%;


// Forms
$form-text-margin-top: .25rem;
$form-text-font-size: $small-font-size;
$form-text-font-style: null;
$form-text-font-weight: null;
$form-text-color: var(--#{$prefix}secondary-color);

$form-label-margin-bottom: .5rem;
$form-label-font-size: null;
$form-label-font-style: null;
$form-label-font-weight: null;
$form-label-color: null;

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-family: $input-btn-font-family;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-base;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;

$input-bg: var(--#{$prefix}secondary-bg);
$input-disabled-color: null;
$input-disabled-bg: var(--#{$prefix}secondary-bg);
$input-disabled-border-color: null;

$input-color: var(--#{$prefix}body-color);
$input-border-color: var(--#{$prefix}border-color);
$input-border-width: $input-btn-border-width;
$input-box-shadow: $box-shadow-inset;

$input-border-radius: var(--#{$prefix}border-radius);
$input-border-radius-sm: var(--#{$prefix}border-radius-sm);
$input-border-radius-lg: var(--#{$prefix}border-radius-lg);

$input-focus-bg: $input-bg;
$input-focus-border-color: tint-color($component-active-bg, 50%);
$input-focus-color: $input-color;
$input-focus-width: $input-btn-focus-width;
$input-focus-box-shadow: $input-btn-focus-box-shadow;

$input-placeholder-color: var(--#{$prefix}secondary-color);
$input-plaintext-color: var(--#{$prefix}body-color);

$form-color-width: 3rem;

$form-check-input-width: 1em;
$form-check-min-height: $font-size-base * $line-height-base;
$form-check-padding-start: $form-check-input-width + .5em;
$form-check-margin-bottom: 0rem;
$form-check-label-color: null;
$form-check-label-cursor: null;
$form-check-transition: null;
$form-check-input-active-filter: brightness(90%);
$form-check-input-bg: $input-bg;
$form-check-input-border: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color);
$form-check-input-border-radius: .25em;
$form-check-radio-border-radius: 50%;
$form-check-input-focus-border: $input-focus-border-color;
$form-check-input-focus-box-shadow: $focus-ring-box-shadow;
$form-check-input-checked-color: $component-active-color;
$form-check-input-checked-bg-color: $component-active-bg;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");
$form-check-input-indeterminate-color: $component-active-color;
$form-check-input-indeterminate-bg-color: $component-active-bg;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");
$form-check-input-disabled-opacity: .5;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity;
$form-check-btn-check-disabled-opacity: $btn-disabled-opacity;
$form-check-inline-margin-end: 1rem;

$form-switch-color: rgba($black, .25);
$form-switch-width: 2em;
$form-switch-padding-start: $form-switch-width + .5em;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");
$form-switch-border-radius: $form-switch-width;
$form-switch-transition: background-position .15s ease-in-out;
$form-switch-focus-color: $input-focus-border-color;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");
$form-switch-checked-color: $component-active-color;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>");
$form-switch-checked-bg-position: right center;

$input-group-addon-padding-y: $input-padding-y;
$input-group-addon-padding-x: $input-padding-x;
$input-group-addon-font-weight: $input-font-weight;
$input-group-addon-color: $input-color;
$input-group-addon-bg: var(--#{$prefix}tertiary-bg);
$input-group-addon-border-color: $input-border-color;

$form-file-button-color: $input-color;
$form-file-button-bg: var(--#{$prefix}tertiary-bg);
$form-file-button-hover-bg: var(--#{$prefix}secondary-bg);


// Form validation
$form-feedback-margin-top: $form-text-margin-top;
$form-feedback-font-size: $form-text-font-size;
$form-feedback-font-style: $form-text-font-style;
$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");

$form-valid-color: $form-feedback-valid-color;
$form-valid-border-color: $form-feedback-valid-color;
$form-invalid-color: $form-feedback-invalid-color;
$form-invalid-border-color: $form-feedback-invalid-color;

$form-validation-states: (
        "valid": (
                "color": var(--#{$prefix}form-valid-color),
                "icon": $form-feedback-icon-valid,
                "tooltip-color": #fff,
                "tooltip-bg-color": var(--#{$prefix}success),
                "focus-box-shadow": 0 0 $input-btn-focus-blur $input-focus-width rgba(var(--#{$prefix}success-rgb), $input-btn-focus-color-opacity),
                "border-color": var(--#{$prefix}form-valid-border-color),
        ),
        "invalid": (
                "color": var(--#{$prefix}form-invalid-color),
                "icon": $form-feedback-icon-invalid,
                "tooltip-color": #fff,
                "tooltip-bg-color": var(--#{$prefix}danger),
                "focus-box-shadow": 0 0 $input-btn-focus-blur $input-focus-width rgba(var(--#{$prefix}danger-rgb), $input-btn-focus-color-opacity),
                "border-color": var(--#{$prefix}form-invalid-border-color),
        )
);

// Z-index master list
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-offcanvas-backdrop: 1040;
$zindex-offcanvas: 1045;
$zindex-modal-backdrop: 1050;
$zindex-modal: 1055;
$zindex-popover: 1070;
$zindex-tooltip: 1080;
$zindex-toast: 1090;
$zindex-levels: (
        n1: -1,
        0: 0,
        1: 1,
        2: 2,
        3: 3
);


// Navs
$nav-link-padding-y: .5rem;
$nav-link-padding-x: 1rem;
$nav-link-font-size: null;
$nav-link-font-weight: null;
$nav-link-color: var(--#{$prefix}emphasis-color);
$nav-link-hover-color: var(--#{$prefix}emphasis-color);
$nav-link-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
$nav-link-disabled-color: var(--#{$prefix}secondary-color);
$nav-link-focus-box-shadow: $focus-ring-box-shadow;

$nav-tabs-border-color: var(--#{$prefix}border-color);
$nav-tabs-border-width: var(--#{$prefix}border-width);
$nav-tabs-border-radius: var(--#{$prefix}border-radius);
$nav-tabs-link-hover-border-color: var(--#{$prefix}secondary-bg) var(--#{$prefix}secondary-bg) $nav-tabs-border-color;
$nav-tabs-link-active-color: var(--#{$prefix}emphasis-color);
$nav-tabs-link-active-bg: var(--#{$prefix}secondary-bg);
$nav-tabs-link-active-border-color: var(--#{$prefix}border-color) var(--#{$prefix}border-color) $nav-tabs-link-active-bg;

$nav-pills-border-radius: var(--#{$prefix}border-radius);
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

$nav-underline-gap: 1rem;
$nav-underline-border-width: .125rem;
$nav-underline-link-active-color: var(--#{$prefix}emphasis-color);


// Navbar
$navbar-padding-y: $spacer * .5;
$navbar-padding-x: null;
$navbar-nav-link-padding-x: .5rem;
$navbar-brand-font-size: $font-size-lg;
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) * .5;
$navbar-brand-margin-end: 1rem;
$navbar-toggler-padding-y: .25rem;
$navbar-toggler-padding-x: .75rem;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: $btn-border-radius;
$navbar-toggler-focus-width: $btn-focus-width;
$navbar-toggler-transition: box-shadow .15s ease-in-out;

$navbar-light-color: rgba(var(--#{$prefix}emphasis-color-rgb), .65);
$navbar-light-hover-color: rgba(var(--#{$prefix}emphasis-color-rgb), .8);
$navbar-light-active-color: rgba(var(--#{$prefix}emphasis-color-rgb), 1);
$navbar-light-disabled-color: rgba(var(--#{$prefix}emphasis-color-rgb), .3);
$navbar-light-icon-color: rgba($body-color, .75);
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color: rgba(var(--#{$prefix}emphasis-color-rgb), .15);
$navbar-light-brand-color: $navbar-light-active-color;
$navbar-light-brand-hover-color: $navbar-light-active-color;
$navbar-dark-color: rgba($white, .55);
$navbar-dark-hover-color: rgba($white, .75);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, .25);
$navbar-dark-icon-color: $navbar-dark-color;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-toggler-border-color: rgba($white, .1);
$navbar-dark-brand-color: $navbar-dark-active-color;
$navbar-dark-brand-hover-color: $navbar-dark-active-color;

// Dropdowns
$dropdown-min-width: 13rem;
$dropdown-padding-x: 0;
$dropdown-padding-y: .5rem;
$dropdown-spacer: .125rem;
$dropdown-font-size: $font-size-base;
$dropdown-color: var(--#{$prefix}body-color);
$dropdown-bg: var(--#{$prefix}secondary-bg);
$dropdown-border-color: var(--#{$prefix}border-color-translucent);
$dropdown-border-radius: var(--#{$prefix}border-radius);
$dropdown-border-width: var(--#{$prefix}border-width);
$dropdown-inner-border-radius: calc(#{$dropdown-border-radius} - #{$dropdown-border-width}); // stylelint-disable-line function-disallowed-list
$dropdown-divider-bg: $dropdown-border-color;
$dropdown-divider-margin-y: $spacer * .5;
$dropdown-box-shadow: $box-shadow;
$dropdown-link-color: var(--#{$prefix}body-color);
$dropdown-link-hover-color: $dropdown-link-color;
$dropdown-link-hover-bg: var(--#{$prefix}tertiary-bg);
$dropdown-link-active-color: var(--#{$prefix}body-color);
$dropdown-link-active-bg: var(--#{$prefix}tertiary-bg);
$dropdown-link-disabled-color: var(--#{$prefix}tertiary-color);
$dropdown-item-padding-y: .35rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $gray-600;
$dropdown-header-padding-x: $dropdown-item-padding-x;
$dropdown-header-padding-y: $dropdown-padding-y;
$dropdown-header-padding: $dropdown-header-padding-y $dropdown-header-padding-x; // Deprecated in v5.2.0
$dropdown-dark-color: $gray-300;
$dropdown-dark-bg: $gray-800;
$dropdown-dark-border-color: $dropdown-border-color;
$dropdown-dark-divider-bg: $dropdown-divider-bg;
$dropdown-dark-box-shadow: null;
$dropdown-dark-link-color: $dropdown-dark-color;
$dropdown-dark-link-hover-color: $white;
$dropdown-dark-link-hover-bg: rgba($white, .15);
$dropdown-dark-link-active-color: $dropdown-link-active-color;
$dropdown-dark-link-active-bg: $dropdown-link-active-bg;
$dropdown-dark-link-disabled-color: $gray-500;
$dropdown-dark-header-color: $gray-500;

// Pagination
$pagination-color: var(--#{$prefix}body-color);
$pagination-bg: var(--#{$prefix}secondary-bg);
$pagination-border-color: var(--#{$prefix}border-color);
$pagination-focus-color: var(--#{$prefix}link-hover-color);
$pagination-focus-bg: var(--#{$prefix}tertiary-bg);
$pagination-focus-box-shadow: $focus-ring-box-shadow;
$pagination-focus-outline: 0;
$pagination-hover-color: var(--#{$prefix}link-color);
$pagination-hover-bg: var(--#{$prefix}tertiary-bg);
$pagination-hover-border-color: var(--#{$prefix}border-color); // Todo in v6: remove this?
$pagination-active-color: $component-active-color;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $component-active-bg;
$pagination-disabled-color: var(--#{$prefix}secondary-color);
$pagination-disabled-bg: var(--#{$prefix}tertiary-bg);
$pagination-disabled-border-color: var(--#{$prefix}border-color);
$pagination-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

// Placeholders
$placeholder-opacity-max: .5;
$placeholder-opacity-min: .2;

// Cards
$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;
$card-title-spacer-y: $spacer * 0.5;
$card-title-color: var(--#{$prefix}heading-color);
$card-subtitle-color: null;
$card-border-width: var(--#{$prefix}border-width);
$card-border-color: var(--#{$prefix}border-color-translucent);
$card-border-radius: var(--#{$prefix}border-radius);
$card-box-shadow: null;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width);
$card-cap-padding-y: 1.10rem;
$card-cap-padding-x: 1.5rem;
$card-cap-bg: var(--#{$prefix}secondary-bg);
$card-cap-color: null;
$card-height: null;
$card-color: null;
$card-bg: var(--#{$prefix}secondary-bg);
$card-img-overlay-padding: $spacer;
$card-group-margin: $grid-gutter-width * 0.5;

$form-feedback-tooltip-padding-y: $tooltip-padding-y;
$form-feedback-tooltip-padding-x: $tooltip-padding-x;
$form-feedback-tooltip-font-size: $tooltip-font-size;
$form-feedback-tooltip-line-height: null;
$form-feedback-tooltip-opacity: $tooltip-opacity;
$form-feedback-tooltip-border-radius: $tooltip-border-radius;

// Badges
$badge-font-size: 75%;
$badge-font-weight: $font-weight-bold;
$badge-color: $white;
$badge-padding-y: .25em;
$badge-padding-x: .6em;
$badge-border-radius: var(--#{$prefix}border-radius);

// Modals
$modal-inner-padding: $spacer;
$modal-content-bg: var(--#{$prefix}secondary-bg);
$modal-content-border-color: var(--#{$prefix}border-color-translucent);
$modal-content-border-width: var(--#{$prefix}border-width);
$modal-content-border-radius: var(--#{$prefix}border-radius-lg);
$modal-backdrop-opacity: .6;

// Alerts
$alert-padding-y: .75rem;
$alert-padding-x: 1.25rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: var(--#{$prefix}border-radius);
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: var(--#{$prefix}border-width);
$alert-dismissible-padding-r: $alert-padding-x * 3; // 3x covers width of x plus default padding on either side
$alert-bg-scale: -80%; // Deprecated in v5.2.0, to be removed in v6
$alert-border-scale: -70%; // Deprecated in v5.2.0, to be removed in v6
$alert-color-scale: 40%; // Deprecated in v5.2.0, to be removed in v6

// Figures
$figure-caption-font-size: $small-font-size;
$figure-caption-color: var(--#{$prefix}secondary-color);

// Close
$btn-close-width: 1em;
$btn-close-height: $btn-close-width;
$btn-close-padding-x: .25em;
$btn-close-padding-y: $btn-close-padding-x;
$btn-close-color: $black;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>");
$btn-close-focus-shadow: $focus-ring-box-shadow;
$btn-close-opacity: .5;
$btn-close-hover-opacity: .75;
$btn-close-focus-opacity: 1;
$btn-close-disabled-opacity: .25;
$btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);


:export {
  @each $key, $value in $theme-colors {
    #{unquote($key)}: $value;
  }
}