@import "../variables";
@import "../variables-dark";

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.custom-card-header{
  @extend .d-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.error-title {
  font-size: 180px;
  text-shadow: 9px 0 $primary;

  @media (max-width: 425px) {
    font-size: 150px;
  }
}

.pointer-event {
  cursor: pointer;
}

.underline-hover {
  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    text-decoration: underline;
  }
}

.grab-event {
  cursor: grab;
}

.blockquote {
  padding: 10px 20px;
  border-left: 4px solid var(--#{$prefix}border-color);
}

.dash-timeline ul.task-list {
  list-style-type: none;
  position: relative;
  margin: 30px 0;

  &::before {
    content: '';
    position: absolute;
    margin: 0 20px;
    display: inline-block;
    left: -5px;
    top: 2px;
    height: 99%;
    z-index: 0;
    border-left: 2px dashed $gray-500;
  }

  &>li {
    margin-bottom: 40px;
    padding-left: 20px;
    position: relative;

    &:last-child {
      margin-bottom: 10px;
    }


    >.task-icon {
      content: "";
      position: absolute;
      left: -30px;
      top: 1px;
      color: $white;
      text-align: center;
      width: 30px;
      height: 30px;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: lighten($gray-500, 10);
      //&:first-child {
      //  top: -5px;
      //}
    }
  }
}


.blockquote-reverse {
  border-left: 0;
  border-right: 4px solid var(--#{$prefix}border-color);
  text-align: right;
}

.overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  background-color: rgba(170, 170, 170, 0.35);
}

.custom-tooltip {
  background-color: $card-bg;
  border: 1px solid rgba(170, 170, 170, 0.35);
  padding: 0 10px;
}

.product-box {
  background-color: $boxed-body-bg;
  padding: 12px 15px;
}

.event-card {
  text-align: left;
  padding: 8px 16px;
  margin: 6px 0;
  color: $white;

  &.shift {
    margin-left: 2rem;
    width: -webkit-fill-available;
  }
}

.centered-content {
   display: flex;
    justify-content: center;
     align-items: center; 
}

.custom-scroll {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE & Edge */
  overflow-y: auto;
}

.custom-scroll::-webkit-scrollbar {
  display: none; /* WebKit (Chrome, Safari) */
}