@import "../variables";
@import "../variables-dark";

.footer {
  bottom: 0;
  padding: 20px calc(#{$grid-gutter-width} / 2);
  position: fixed;
  right: 0;
  color: var(--#{$prefix}body-color);
  left: 0;
  height: 60px;
  background-color: $card-bg;
  border-top: $card-border-width solid $card-border-color;
  box-shadow: var(--#{$prefix}box-shadow);
  z-index: 5;

  .container-fluid {
    width: 98%;
  }
}

@media (max-width: 992px) {
  .footer {
    left: 0;
  }
}

