@import "../variables";
@import "../variables-dark";

.avatar-xs {
  height: 1rem;
  width: 1rem;
}

.avatar-sm {
  height: 2.2rem;
  width: 2.2rem;
}

.avatar {
  height: 3rem;
  width: 3rem;
}

.avatar-md {
  height: 3.6rem;
  width: 3.6rem;
}

.avatar-lg {
  height: 5rem;
  width: 5rem;
}

.avatar-xl {
  height: 6rem;
  width: 6rem;
}

.avatar-title {
  align-items: center;
  background-color: $primary;
  color: $white;
  display: flex;
  font-weight: $font-weight-medium;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.avatar-group {
  display: flex;
  flex-wrap: wrap;
  padding-left: 8px;
  .avatar-group-item {
    margin-left: -8px;
    border: 2px solid $card-bg;
    border-radius: 50%;
    transition: all 0.2s;
    &:hover{
      position: relative;
      transform: translateY(-2px);
    }
  }
}

.user-profile-img{
  position: relative;
  .overlay-content{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba($primary, 0.8);
    display: flex;
    height: 100%;
    color: rgba($white, 0.6);
    flex-direction: column;
  }
  .profile-img{
    width: 100%;
    height: 250px;
    object-fit: cover;

    @media (max-width:991px) {
      height: 160px;
    }
  }
}

.user-profile-image{
  object-fit: cover;
}