@import "../variables";
@import "../variables-dark";

.table {
  th {
    font-weight: $table-th-font-weight;
  }

  .table-light {
    color: var(--#{$prefix}body-color);
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;
}

.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}


.table-responsive {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 12px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 9px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($dark, .2);
    border-radius: 10px;
    border: 2px solid $white;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $white;
  }
}


.table-search {
  .form-control {
    border: 0;
    padding: 0.6rem 0.75rem;
  }

  span {
    position: absolute;
    z-index: 10;
    line-height: 38px;
    right: 13px;
    top: 2px;
    color: $gray-600;
  }
}

.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 5px;
    border: none;
    width: 32px;
    height: 32px;
    padding: 0;
    text-align: center;
    line-height: 32px;
  }
}

.pag-container {
  color: $table-color;
}

.pag-footer {
  box-shadow: none;
  border: none;
  border-top: 0;
  border-radius: 0 0 8px 8px;
  display: block;
  padding: 12px 24px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.pag-pagination {
  color: $pagination-color;

  .pag-summary {
    float: left;
    margin-top: 5px;
  }

  .pag-pages {
    float: right;
  }

  .pag-pages button {
    border: none !important;
    border-radius: 30px !important;
    margin: 0 5px;
    min-width: 32px;
    height: 32px;
    padding: 0;
    text-align: center;
    line-height: 32px;

    &.item {
      border: none !important;
      padding: 0;
      background: none;
    }

    &:disabled,
    &:hover:disabled,
    &[disabled] {
      color: $pagination-disabled-color;
    }

    &:hover {
      background-color: $pagination-hover-bg;
      color: $pagination-hover-color;
    }

    &:focus {
      box-shadow: none;
      margin: 0 5px !important;
    }

    &:last-child, &:first-child {
      &:hover {
        background-color: transparent;
      }
    }

    &.pag-currentPage {
      background-color: $pagination-active-bg;
      color: $pagination-active-color;
      font-weight: 500;
      border-color: $pagination-active-border-color;
    }
  }
}
