@import "../variables";
@import "../variables-dark";

.dropdown-menu {
  box-shadow: $box-shadow-lg;
  animation-name: DropDownSlide;
  animation-duration: .3s;
  animation-fill-mode: both;
  position: absolute;
  z-index: 1000;

  &.show {
    position: absolute;
    inset: 0 0 auto auto;
    margin: 0;
    transform: translate3d(0px, 10px, 0px);
  }

  &.dropdown-megamenu {
    padding: 20px;
    left: 0 !important;
    right: 0 !important;
  }

  &[data-popper-placement="top-start"] {
    animation-name: DropDownSlideDown;
  }
}

@keyframes DropDownSlide {
  100% {
    margin-top: -1px;
  }
  0% {
    margin-top: 8px;
  }
}

@keyframes DropDownSlideDown {
  100% {
    margin-bottom: 0;
  }
  0% {
    margin-bottom: 8px;
  }
}

@media (min-width: 600px) {
  .dropdown-menu-xl {
    width: 600px;
  }

  .dropdown-menu-lg {
    width: 400px;
  }

  .dropdown-menu-md {
    width: 240px;
  }
}

.dropdown-divider {
  border-top-color: var(--#{$prefix}border-color);
}

@each $color,
$value in $theme-colors {
  .dropdownmenu-#{$color} {
    .dropdown-item {
      &:focus,
      &:hover,
      &.active {
        background-color: rgba(($value), 0.07) !important;
        color: $value;
      }
    }
  }
}

[dir="ltr"] {
  .dropdown-menu-start {
    --bs-position: end;
  }

  .dropdown-menu-end {
    --bs-position: start;
  }
}


body[data-bs-theme="bordered"] {
  .dropdown-menu {
    box-shadow: none;
  }
}